<template>
  <section id="open-offers">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search by offer no." @keyup.enter="searchOrder" />
          </b-input-group>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="6" md="6">
          <v-select v-model="selectedContactPerson" label="title" placeholder="Contact Person"
            :options="allContactPersons" @input="changeSelectedContactPerson" />
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedProcessStatus" label="title" placeholder="Status" :options="allProcessStatuses"
            @input="changeSelectedProcessStatus" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Open offers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-open-offers" />
          </h4>
          <b-popover target="popover-open-offers" triggers="hover" placement="bottom">
            <span>The Open offers section displays a table of all open offers with columns for offer number,
              customer name, contact person, status, creation date, and more. You can add text notes and reminders by
              clicking on a table row. Table filters include date range, offer number search, and contact person
              filter.</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="openOffersTable.currentPage"
          :items="openOffersTable.items" :fields="openOffersTable.fields" :sort-by.sync="openOffersTable.sortBy"
          :sort-desc.sync="openOffersTable.sortDesc" :sort-direction="openOffersTable.sortDirection"
          :filter="openOffersTable.filter" :filter-included-fields="openOffersTable.filterOn"
          @sort-changed="sortChanged" @row-clicked="orderDetailsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="openOffersTable.currentPage" :total-rows="openOffersTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleOpenOffersPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-order-details-submit" size="xl" title="Offer Details" cancel-variant="outline-secondary"
        cancel-title="Close" centered :no-close-on-backdrop="true" @hidden="resetOrderDetailsModal">
        <b-row>
          <b-col xl="2">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ offerNo }}
                  </h2>
                  <span>Offer No.</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3">
            <b-row>
              <b-col xl="12">
                <b-card no-body>
                  <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                      <h2 class="mb-25 font-weight-bolder" v-if="offerDetails.status">
                        {{ offerDetails.status }}
                      </h2>
                      <h2 class="mb-25 font-weight-bolder" v-else>
                        No status
                      </h2>
                      <span>Status</span>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12">
                <b-card no-body>
                  <b-card-body>
                    <b-form @submit.prevent="updateStatus">
                      <b-form-group label="Status" label-for="newStatus">
                        <v-select v-model="newStatus" label="title" :options="allProcessStatuses" />
                      </b-form-group>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                        type="submit" v-if="offerDetails.status">
                        Update Status
                      </b-button>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                        type="submit" v-else>
                        Set Status
                      </b-button>
                    </b-form>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
          <b-col xl="4">
            <b-card no-body>
              <b-card-body class="d-flex justify-content-between align-items-center">
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ offerDetails.note }}
                  </h2>
                  <span>Note</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col xl="3">
            <b-row>
              <b-col xl="12">
                <b-card no-body>
                  <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                      <h2 class="mb-25 font-weight-bolder" v-if="offerDetails.comment">
                        {{ offerDetails.comment }}
                      </h2>
                      <h2 class="mb-25 font-weight-bolder" v-else>
                        No comment
                      </h2>
                      <span>Customer comment</span>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col xl="12">
                <b-card no-body>
                  <b-card-body>
                    <b-form @submit.prevent="updateComment">
                      <b-form-group label="Comment" label-for="newCommentText">
                        <b-input-group class="input-group-merge"
                          :class="validationItem(newComment) ? 'is-valid' : 'is-invalid'">
                          <b-form-textarea id="newCommentText" v-model="newComment" rows="4" />
                        </b-input-group>
                        <b-form-invalid-feedback v-if="submittedNewComment && !validationItem(newComment)">
                          Empty string
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                        type="submit" v-if="offerDetails.comment">
                        Update Comment
                      </b-button>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                        type="submit" v-else>
                        Add Comment
                      </b-button>
                    </b-form>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Offer positions
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-offer-positions" />
            </h4>
            <b-popover target="popover-offer-positions" triggers="hover" placement="bottom">
              <span>Offer positions</span>
            </b-popover>
          </b-card-header>
          <b-table v-if="showOfferPossTable" striped hover responsive class="position-relative"
            :current-page="offerPossTable.currentPage" :items="offerPossTable.items" :fields="offerPossTable.fields"
            :sort-by.sync="offerPossTable.sortBy" :sort-desc.sync="offerPossTable.sortDesc"
            :sort-direction="offerPossTable.sortDirection" />
        </b-card>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardHeader,
  BCardBody,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPopover,
  BButton,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardHeader,
    BCardBody,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPopover,
    vSelect,
    flatPickr,
    ToastificationContent,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      showOfferPossTable: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      selectedContactPerson: '',
      selectedProcessStatus: { id: null, title: null },
      allContactPersons: [],
      allProcessStatuses: [],
      offerID: '',
      offerNo: '',
      offerDetails: { offer_no: null, note: null, comment: null },
      newStatus: { id: null, title: null },
      newComment: '',
      submittedNewComment: false,
      queryParams: {},
      queryOrderNotes: {},
      openOffersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'created_at',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'offer_no', label: 'offer no', sortable: true },
          { key: 'customer_name', label: 'customer name', sortable: true },
          { key: 'customer_email', label: 'customer email', sortable: true },
          { key: 'customer_tel', label: 'customer tel.', sortable: true },
          {
            key: 'total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'contact_person', label: 'contact person', sortable: true },
          { key: 'status', label: 'status', sortable: true },
          {
            key: 'created_at',
            label: 'created at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      offerPossTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'item_sku',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'item_sku', label: 'item sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'pprice',
            label: 'purchasing price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'discount',
            label: 'discount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'discounted_price',
            label: 'discounted price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'total_price',
            label: 'total price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getContactPersons();
      await this.getProcessStatuses();
      await this.getOpenOffers();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    validationItem(value) {
      return isNaN(value);
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getContactPersons() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/jtl-support-employee-names/`, {});
        this.allContactPersons = response.data.results.map(item => item.full_name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getProcessStatuses() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-process-statuses/`, {});
        this.allProcessStatuses = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getOpenOffers() {
      this.show = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-offers/`, this.queryParams);
        this.openOffersTable.items = response.data.results;
        this.openOffersTable.totalRows = response.data.results[0].count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getOfferDetails() {
      this.showOfferPossTable = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-offer-details/`, { offer_no: this.offerNo });
        this.offerDetails = response.data.results[0];
        this.newStatus = { id: this.offerDetails.status_id, title: this.offerDetails.status };
        this.newComment = this.offerDetails.comment;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOfferPossTable = true;
      }
    },
    async getOfferPoss() {
      this.showOfferPossTable = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/open-offer-pos/`, { offer_no: this.offerNo });
        this.offerPossTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showOfferPossTable = true;
      }
    },
    async updateStatus() {
      if (this.newStatus) {
        const params = {
          offer_id: this.offerID,
          status_id: this.newStatus.id
        };

        try {
          const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/update-offer-status/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          });
          await this.getOfferDetails();
          this.showToast("success", response.data.message);
        } catch (error) {
          console.log(error)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            console.error('An error occurred:', error);
            this.showToast("danger", "Something went wrong");
          }
        }
      } else {
        this.showToast("warning", "Empty input fields");
      }
    },
    async updateComment() {
      this.submittedNewComment = true;
      if (this.newComment) {
        const params = {
          offer_id: this.offerID,
          comment: this.newComment
        };

        try {
          const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/update-offer-comment/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          });
          await this.getOfferDetails();
          this.showToast("success", response.data.message);
        } catch (error) {
          console.log(error)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login' });
          } else {
            console.error('An error occurred:', error);
            this.showToast("danger", "Something went wrong");
          }
        }
      } else {
        this.showToast("warning", "Empty input fields");
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getOpenOffers();
        this.oldDateRange = this.dateRange;
      }
    },
    async searchOrder() {
      this.queryParams.page = 1;
      this.queryParams.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getOpenOffers();
    },
    async changeSelectedContactPerson() {
      this.queryParams.contact_person = this.selectedContactPerson;
      await this.getOpenOffers();
    },
    async changeSelectedProcessStatus() {
      this.queryParams.process_status = this.selectedProcessStatus.title;
      await this.getOpenOffers();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParams.ordering = `-${value.sortBy}`;
      } else {
        this.queryParams.ordering = value.sortBy;
      }
      await this.getOpenOffers();
    },
    async handleOpenOffersPageChange(value) {
      this.queryParams.page = value;
      await this.getOpenOffers();
    },
    async orderDetailsRowClicked(row) {
      this.offerID = row.offer_id;
      this.offerNo = row.offer_no;
      await this.getOfferDetails();
      await this.getOfferPoss();
      this.$refs['modal-order-details-submit'].show();
    },
    resetOrderDetailsModal() {
      this.offerNo = '';
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
